<template>
	<row>
		<column cols="12" md="7" lg="8">
			<card v-if="[EventStatus.Canceled].indexOf(eventStore.event.status.value) >= 0"
				  class="bg-error" xs-tile sm-tile dark>
				<card-title>{{ eventStore.event.status.title }}</card-title>
				<card-text>
					<div v-html="viewData.cancel_reason ?? eventStore.event.status.description"/>
				</card-text>
			</card>

			<card v-else-if="viewData.latestActivity" xs-tile sm-tile>
				<lazy-activity :activity="viewData.latestActivity" :color="eventStore.colorStyle" without-edit xs-tile sm-tile flat no-spacing/>
				<btn :to="$web('events.activities.index', eventStore.event._key)" replace text :color="eventStore.colorStyle" class="rounded-t-none border-t w-full">
					<span>{{ $t('action.show_all_news') }}</span>
					<icon :icon="icons.next"/>
				</btn>
			</card>

			<card id="results" v-if="viewData.participationClassResults.length" xs-tile sm-tile>
				<card-title spaced-content>
					<div class="w-full flex justify-between items-center">
						<h2>{{ $t('label.results') }}</h2>
						<nuxt-link v-if="eventStore.hasActivePage(EventPageType.Results)" class="text-sm font-regular font-text" :style="{color: eventStore.colorStyle}" :to="$web('events.results.index', [eventStore.event._key])">{{ $t('action.show_all') }}</nuxt-link>
					</div>
				</card-title>

				<expansion-panels :model-value="[0]" class="pb-16">
					<expansion-panel v-for="(participationClass, participationClassIndex) in viewData.participationClassResults" :key="participationClass.id" :title="participationClass.name" padding="px-16 md:px-24">
						<div class="px-0 md:px-24">
							<simple-table :headers="resultHeaders[participationClassIndex]" dense>
								<template v-for="list in participationClass.resultLists" :key="list.id">
									<table-group-row :group="{title: list.name}" :columns="resultHeaders[participationClassIndex]"/>
									<tr v-for="rank in list.ranks" :key="`rank-${rank.id}`">
										<table-body-row-data v-for="header in resultHeaders[participationClassIndex]" :header="header" :key="`rank-${rank.id}-${header.value}`" :item="rank">
											<template v-if="header.value === 'club'" #default>
												<div v-if="rank.club" class="flex items-center">
													<avatar :src="rank.club.avatar_thumbnail" :size="20" tile/>
													<span class="table-text-line ml-8 none sm:block">{{ rank.club.display_name }}</span>
												</div>
												<span v-else>-</span>
											</template>
											<template v-else-if="header.value === 'name'" #default>
												<nuxt-link v-if="rank.participation_id"
														   :to="$web('events.participations.show', [eventStore.event._key, rank.participation_id])"
														   :style="{color: eventStore.colorStyle}"
														   class="table-text-line">{{ rank.name }}</nuxt-link>
												<span v-else class="table-text-line">{{ rank.name }}</span>
											</template>
										</table-body-row-data>
									</tr>
									<tr>
										<td :colspan="resultHeaders[participationClassIndex].length" class="p-0">
											<btn :to="$app('events-event-results-group', {params: {event: eventStore.event._key, group: list.group._key}, query: {list: list._key}, state: {group_ts: list.group.cache_buster}})"
												 text :color="eventStore.colorStyle"
												 class="rounded-t-none w-full">
												<span>{{ $t('action.show_all') }}</span>
												<icon :icon="icons.next"/>
											</btn>
										</td>
									</tr>
								</template>
							</simple-table>
						</div>
					</expansion-panel>
				</expansion-panels>

			</card>

			<card id="description" v-if="viewData.description || infoFacts.length" xs-tile sm-tile>
				<card-title><h2>{{ $t('label.description') }}</h2></card-title>
				<card-text>

					<user-content v-if="viewData.description" class="mb-24" :text="viewData.description"/>

					<info-list v-if="infoFacts.length">
						<info-list-item v-for="(infoFact, infoFactIndex) in infoFacts" :key="infoFactIndex" :icon="infoFact.icon" :title="infoFact.title" :help="infoFact.help">
							<nuxt-link v-if="infoFact.to" :to="infoFact.to" :target="infoFact.to.startsWith('/') ? undefined : '_blank'" rel="nofollow noopener">
								{{ infoFact.text }}
							</nuxt-link>
							<span v-else>{{ infoFact.text }}</span>
						</info-list-item>
					</info-list>
					<template v-if="eventStore.event.attendance_mode === EventAttendanceMode.Virtual && viewData.supportedTrackerTypes.length">
						<divider/>

						<p>{{ $t('text.event.virtual', {event: eventStore.event.display_name}) }}</p>

						<row tight>
							<column cols="12" sm="6" v-for="trackerType in viewData.supportedTrackerTypes" :key="trackerType.value">
								<tooltip with-touch top :text="trackerType.description">
									<document-item :title="trackerType.compatibility_image ? undefined : trackerType.title"
												   :icon="trackerType.icon"
												   :icon-width="20">
										<template v-if="trackerType.image" #icon>
											<cdn-image :size="{width: 20}" :ratio="1" :src="trackerType.image"/>
										</template>
										<template v-if="trackerType.compatibility_image" #description>
											<cdn-image :size="{width: 124}" :ratio="trackerType.compatibility_image.ratio" :src="trackerType.compatibility_image"/>
										</template>
										<template #suffix>
												<icon :icon="icons.help" x-small color="gray-400" class="ml-16"/>
										</template>
									</document-item>
								</tooltip>
							</column>
						</row>
					</template>
				</card-text>
			</card>

			<card v-if="eventStore.event.allows_partner_ads && !$device.isMobile" xs-tile sm-tile>
				<qmn-ad :config="QmnAds.DesktopIntextAd"/>
			</card>

			<event-participants-card v-if="!$device.isDesktop && shouldDisplayParticipantsInfo" id="participants"
									 :participants-count="viewData.participants_count"
									 :community-participants-count="viewData.community_participants_count"/>

			<card v-if="eventStore.event.allows_partner_ads && $device.isMobile" xs-tile sm-tile>
				<qmn-ad :config="QmnAds.MobileHPATopAreaAd"/>
			</card>

			<card id="details" v-if="eventStore.event.registration_info || viewData.descriptions.length || (viewData.qualityFeatures && viewData.qualityFeatures.length)" xs-tile sm-tile>
				<card-title spaced-content>{{ $t('label.advanced_information') }}</card-title>
				<card-text>
					<expansion-panels v-model="openPanels">
						<expansion-panel v-if="eventStore.event.registration_info" :title="$t('label.registration_info')" value="register">
							<user-content :text="eventStore.event.registration_info" small/>
						</expansion-panel>
						<template v-for="description in viewData.descriptions" :key="description.id">
							<expansion-panel v-if="description.content"
											 :title="description.title ? description.title : $t(`event_description.${description.type}.label`)" title-tag="h2" :value="description.slug">
								<user-content :text="description.content" small/>
							</expansion-panel>
						</template>
						<expansion-panel v-if="viewData.qualityFeatures && viewData.qualityFeatures.length" :title="$t('label.event_features')" title-tag="h2" value="features">
							<row>
								<column cols="12" md="6" v-for="(qualityFeature, qualityFeatureKey) in viewData.qualityFeatures" :key="qualityFeatureKey">
									<p class="text-sm subheading">{{ qualityFeature.group }}</p>
									<check-list class="mb-12">
										<check-list-item v-for="(requirement, requirementKey) in qualityFeature.items" :key="requirementKey" :help="requirement.description"
														 :text="requirement.title"/>
									</check-list>
								</column>
							</row>
						</expansion-panel>
					</expansion-panels>
				</card-text>
			</card>

			<card id="categories" v-if="viewData.participationClasses.length" xs-tile sm-tile>
				<card-title spaced-content>
					<div class="w-full flex justify-between items-center">
						<h2>{{ $t('label.participation_classes') }}</h2>
						<nuxt-link class="text-sm font-regular font-text" :style="{color: eventStore.colorStyle}" :to="$web('events.participation_classes.index', [eventStore.event._key])">{{ $t('action.show_details') }}</nuxt-link>
					</div>
				</card-title>
				<participation-class-preview v-for="participationClass in viewData.participationClasses" :key="participationClass.id"
											 :participation-class="participationClass" divided
											 :to="$web('events.participation_classes.show', [eventStore.event._key, participationClass._key])"/>
			</card>

			<card id="map" v-if="mapPreviewData || mapPreviewDataPending || mapPreviewDataError" xs-tile sm-tile>
				<card-title>
					<div class="w-full flex justify-between items-center">
						<h2>{{ $t('label.event_location') + (viewData.has_tracks ? ` & ${$t('label.tracks')}` : '') }}</h2>
						<nuxt-link class="text-sm font-regular font-text" :style="{color: eventStore.colorStyle}" :to="$web('events.map.index', [eventStore.event._key])">{{ $t('action.show_details') }}</nuxt-link>
					</div>
				</card-title>
				<card-subtitle v-if="viewData.has_tracks">{{ $t('view.events.map.index.description') }}</card-subtitle>
				<fetch-loader v-if="mapPreviewDataPending || isNavigatingToMapPage || mapPreviewDataError" :pending="mapPreviewDataPending || isNavigatingToMapPage"
							  :color="eventStore.colorStyle"
							  :error="mapPreviewDataError" @retry="refreshMapPreviewData" height="320"/>
				<map-map v-else-if="mapPreviewData" :center="eventStore.event.lat && eventStore.event.lng ? [eventStore.event.lat, eventStore.event.lng] : undefined"
						 :zoom="mapPreviewData.tracks.length ? 13 : 8"
						 hide-controls cursor="pointer sm:rounded"
						 @click="navigateToMapPage"
						 :controllable="false" class="h-320">
					<map-marker v-if="eventStore.event.lat && eventStore.event.lng" :lat-lng="[eventStore.event.lat, eventStore.event.lng]"
								:width="32" :height="32"
								:color="eventStore.colorStyle"/>
					<map-polyline v-for="track in mapPreviewData.tracks" :key="track.id" :lat-lngs="track.latlngs" :color="track.color"/>
				</map-map>
			</card>

			<card id="stay" v-if="staySource" xs-tile sm-tile>
				<card-title>
					<div class="w-full flex justify-between items-center">
						<h2>{{ $t('label.accommodation') }}</h2>
						<nuxt-link class="text-sm font-regular font-text" :style="{color: eventStore.colorStyle}" :to="$web('events.stay.index', [eventStore.event._key])">{{ $t('action.show_details') }}</nuxt-link>
					</div>
				</card-title>
				<card-subtitle>{{ $t('view.events.stay.index.description') }}</card-subtitle>

				<iframe :src="staySource" id="stay22-widget" width="100%" height="400" frameborder="0"/>
			</card>

			<lazy-request-management-card v-if="!eventStore.event.is_managed_by_organizer" :model="eventStore.event" mobile-tile/>

		</column>
		<column cols="12" md="5" lg="4">

			<div v-if="viewData.spotlightItems.length">
				<lazy-event-feature-element-card :elements="viewData.spotlightItems" :event="eventStore.event" xs-tile sm-tile/>
			</div>

			<event-participants-card v-if="$device.isDesktop && shouldDisplayParticipantsInfo" id="participants"
									 :participants-count="viewData.participants_count"
									 :community-participants-count="viewData.community_participants_count"/>

			<card v-for="group in viewData.groups" :key="group.id" xs-tile sm-tile>
				<card-title>{{ group.name }}</card-title>
				<card-subtitle :spaced-content="!!(group.current || group.previous)">{{ $t(`enum.event_group_type.${group.type.value}.event_description`) }}</card-subtitle>
				<profile-preview v-if="group.current" :model="group.current" :to="$app('events-event', {params: {event: group.current._key}, state: {event_ts: group.current.cache_buster}})" :toptitle="$t('label.current_event')" no-spacing flat
								 class="lg:px-24"/>
				<profile-preview v-if="group.previous" :model="group.previous" :to="$app('events-event', {params: {event: group.previous._key}, state: {event_ts: group.previous.cache_buster}})" :toptitle="$t('label.previous_event')" no-spacing flat
								 class="lg:px-24"/>
				<btn :to="$app('events-groups-group', {params: {group: group._key}, state: {group_ts: group.cache_buster}})" text
					 :color="eventStore.colorStyle" class="rounded-t-none border-t w-full">
					<span>{{ $t('action.show_all_events') }}</span>
					<icon :icon="icons.next"/>
				</btn>
			</card>

			<card id="organizer" v-if="viewData.clubs.length" xs-tile sm-tile>
				<card-title spaced-content><h2>{{ $t('label.organized_by') }}</h2></card-title>
				<profile-preview v-for="club in viewData.clubs" :key="club.id" :model="club" :to="$web('clubs.show', club._key)" flat class="lg:px-24 divided"/>
			</card>

			<card v-if="eventStore.event.allows_partner_ads && $device.isMobile" xs-tile sm-tile>
				<qmn-ad :config="QmnAds.MobileHPABottomAreaAd"/>
			</card>

			<lazy-social-list v-if="viewData.socialMedia && viewData.socialMedia.has_any" :social-media="viewData.socialMedia"/>

			<card id="sports" v-if="eventStore.event.sportTypes?.length" xs-tile sm-tile>
				<card-title><h2>{{ $t('label.sports') }}</h2></card-title>

				<card-text>
					<chip v-for="sportType in eventStore.event.sportTypes" :key="'sport-' + sportType.id" class="m-2"
						  :to="$app('sports-sport', {params: {sport: sportType._key}, state: {sport_ts: sportType.cache_buster}})">{{ sportType.title }}
					</chip>
				</card-text>
			</card>

			<card v-if="eventStore.event.allows_partner_ads" xs-tile sm-tile>
				<watchbetter-tip-of-the-day-embed category="sport_fitness" autoplay/>
			</card>


		</column>
		<column v-if="eventStore.event.allows_partner_ads" cols="12">
			<card xs-tile sm-tile>
				<template v-if="$device.isMobile">
					<qmn-ad :config="QmnAds.MobileProspektAd"/>
				</template>
				<template v-else>
					<qmn-ad :config="QmnAds.DesktopProspektAd"/>
				</template>
			</card>
		</column>
	</row>
</template>

<script setup lang="ts">
import {mdiAccountGroup, mdiCheckboxMarkedCircleOutline, mdiCreditCardOutline, mdiLogin, mdiSquareEditOutline} from '@mdi/js'
import {EventAttendanceMode, EventPageType, EventStatus, join, stripHtml} from "@spoferan/spoferan-ts-core";
import {useEventStore} from "../../../store/event";
import {nextIcon, helpIcon} from "@spoferan/nuxt-spoferan/icons";
import {useGoogleSchema} from "../../../composables/useGoogleSchema";
import {getCountryEmoji, getSportTypeEmoji} from "../../../helpers/emoji_helpers";
import {QmnAds} from "../../../config/qmn";

const eventStore = useEventStore();
const {getClubOrganizationSchema} = useGoogleSchema();
const route = useRoute();
const router = useRouter();
const {$api, $date, $web, $image} = useNuxtApp();
const {t, n} = useI18n();
const {value: cacheBusterValue} = useCacheBuster('event_ts');

const {reactiveData: viewData} = await useApiFetch(`/v1/view/events/${eventStore.event.id}/overview`, {
	guest: true,
	params: {
		ts: cacheBusterValue
	}
});

const resultHeaders = viewData.value.participationClassResults.map(participationClass => [
	{ abbreviation: t('abbreviation.rank'), text: t('label.rank'), value: 'rank'},
	{text: t('label.name'), value: 'name'},
	{text: t('label.club'), value: 'club'},
	{text: participationClass.resultLists[0].metric.title, value: 'value'},
]);

const {reactiveData: mapPreviewData, pending: mapPreviewDataPending, error: mapPreviewDataError, refresh: refreshMapPreviewData} = eventStore.hasActivePage(EventPageType.Map) ? (await useApiFetch(`/v1/view/events/${eventStore.event.id}/map/preview`, {
	lazy: true,
	server: false,
	guest: true,
	params: {
		ts: eventStore.event.map_cache_buster,
	}
})) : {reactiveData: ref(null), error: null, pending: false, refresh: null};
const isNavigatingToMapPage = ref(false);

// We check for `registered_participants_count` as we do not want to display the participants info if the organizer has manually added participants (without enabled signup)
const shouldDisplayParticipantsInfo = eventStore.event.has_managed_sign_up ? eventStore.hasActivePage(EventPageType.Participations)
	: (viewData.value.registered_participants_count === 0 || eventStore.hasActivePage(EventPageType.Participations));

const icons = {
	payment: mdiCreditCardOutline,
	participations: mdiAccountGroup,
	create_rating: mdiSquareEditOutline,
	next: nextIcon,
	help: helpIcon,
};

const infoFacts: {icon: string, text: string, title?: string, to?: string, help?: string}[] = [];
if (viewData.value.approval_number) {
	infoFacts.push({
		icon: mdiCheckboxMarkedCircleOutline,
		text: `${t('label.approval_number')}: ${viewData.value.approval_number}`
	})
}
if (eventStore.event.currentMaxPrice && eventStore.event.currentMaxPrice.cents <= 0) {
	infoFacts.push({
		icon: icons.payment,
		text: t('label.free_participation')
	})
}
if (!eventStore.event.has_managed_sign_up && $date(eventStore.event.register_end_date).isAfter() && eventStore.event.external_url) {
	infoFacts.push({
		icon: mdiLogin,
		title: t('label.registration_on_external_page'),
		text: eventStore.event.external_url_host ?? eventStore.event.external_url,
		to: eventStore.event.external_url,
	})
}

if (!eventStore.isFinished && viewData.value.restrictions && Object.keys(viewData.value.restrictions).length) {
	if (viewData.value.restrictions.min_limit && parseInt(viewData.value.restrictions.min_limit)) {
		infoFacts.push({
			icon: icons.participations,
			text: t('restriction.min_limit', {count: viewData.value.restrictions.min_limit, label: t('label.participants')}),
			help: t('restriction.help.min_limit', {count: viewData.value.restrictions.min_limit})
		})
	}
	if (viewData.value.restrictions.limit) {
		infoFacts.push({
			icon: icons.participations,
			text: t('restriction.max_limit', {count: viewData.value.restrictions.limit, label: t('label.participants')}),
		})
	}
}

watch(() => eventStore.authPermissions, (newAuthPermissions, oldAuthPermissions) => {
	if (oldAuthPermissions.is_initialized && !oldAuthPermissions.is_participant && newAuthPermissions.is_participant) {
		viewData.value.community_participants_count++;
	}
});

const openPanels = ref(route.hash?.length ? [route.hash.substr(1)] : []);
watch(openPanels, (panels) => {
	if (panels && panels.length) {
		const openHash = `#${panels[panels.length - 1]}`;
		if (route.hash !== openHash) {
			router.replace({hash: openHash}).catch(() => {
			}); // Might throw duplicated navigation error
		}
	} else {
		router.replace({hash: null}).catch(() => {
		}); // Might throw duplicated navigation error
	}
}, {deep: true});

function navigateToMapPage() {
	// As the map page might take some time to load depending on the amount of map elements,
	// we display a loading state to the user to prevent him from clicking the button multiple times
	isNavigatingToMapPage.value = true;
	router.push(`/events/${eventStore.event._key}/map`);
}

const staySource = computed(() => {
	if (!eventStore.hasActivePage(EventPageType.Stay)) {
		return;
	}

	const color = eventStore.colorStyle.split('#')[1];
	const params = {
		aid: 'spoferan',
		campaign: `event_${eventStore.event.id}-overview`,
		lat: eventStore.event.lat,
		lng: eventStore.event.lng,
		markerimage: $image.format(eventStore.event.avatar_thumbnail, {width: 55, height: 55}),
		eventstart: $date(eventStore.event.start_date).unix(),
		eventend: $date(eventStore.event.end_date).unix(),
		maincolor: color,
		viewmode: 'map',
		hidesearchbar: true,
	};

	return 'https://www.stay22.com/embed/gm?' + Object.keys(params).map(key => `${key}=${params[key]}`).join('&')
});

function getSeoDescription() {
	let textParts = [];

	let sportTypes = '';
	let sportTypeEmoji = '';
	if (eventStore.event.sportTypes && eventStore.event.sportTypes.length) {
		eventStore.event.sportTypes.forEach((sportType, sportTypeIndex) => {
			let name = '';
			if (sportTypeIndex === (eventStore.event.sportTypes.length - 1)) {
				name = sportType.title + '-' + t('label.event');
			} else if (sportTypeIndex === (eventStore.event.sportTypes.length - 2)) {
				name = sportType.title + '- ' + t('conjunction.and') + ' ';
			} else {
				name = sportType.title + '- , '
			}

			if (sportTypeEmoji === '') {
				sportTypeEmoji = getSportTypeEmoji(sportType);
			}
			sportTypes += name;
		})
	} else {
		sportTypes = t('label.event');
	}

	const mainDescriptionParts = [t('label.tender')];

	if (!eventStore.isFinished) {
		if (eventStore.event.has_managed_sign_up) {
			mainDescriptionParts.push(t('label.registration'));
		}
	}
	if (eventStore.event.pages.find(page => page.id === EventPageType.Results && page.is_visible_on_nav)) {
		mainDescriptionParts.push(t('label.results'));
	} else if (eventStore.event.pages.find(page => page.id === EventPageType.Participations && page.is_visible_on_nav)) {
		mainDescriptionParts.push(t('label.participants'));
	}
	if (eventStore.event.pages.find(page => page.id === EventPageType.Map && page.is_visible_on_nav)) {
		if (viewData.value.has_tracks) {
			mainDescriptionParts.push(t('label.track'));
		} else {
			mainDescriptionParts.push(t('label.event_location'));
		}
	}
	if (eventStore.event.pages.find(page => page.id === EventPageType.Stay && page.is_visible_on_nav)) {
		mainDescriptionParts.push(t('label.accommodation'));
	}

	const mainDescriptionPrefix = join(mainDescriptionParts, ', ', ' ' + t('conjunction.and') + ' ');

	const virtualSportTypeSuffix = eventStore.event.attendance_mode === EventAttendanceMode.Virtual ? t('conjunction.of_virtual_event') : t('conjunction.of_event');
	const mainDescription = `${mainDescriptionPrefix} ${virtualSportTypeSuffix} ${sportTypes}`;

	textParts.push(mainDescription + ' ' + eventStore.event.display_name + (sportTypeEmoji !== '' ? (' ' + sportTypeEmoji) : ''));

	if (eventStore.event.start_date) {
		textParts.push(t('label.event_date') + ': ' + $date(eventStore.event.start_date).formatDateRange(eventStore.event.end_date));
	}

	if (viewData.value.country_code) {
		const emoji = getCountryEmoji(viewData.value.country_code);
		textParts.push(t('label.location') + ': ' + eventStore.event.full_address + (emoji !== '' ? (' ' + emoji) : ''));
	}

	if (eventStore.event.rating.count) {
		let emoji = '';
		for (let i = 0; i < Math.round(eventStore.event.rating.avg); i++) {
			emoji += '⭐';
		}
		textParts.push(t('label.rating') + ': ' + eventStore.event.rating.avg.toFixed(2) + ' ' + emoji);
	}

	return textParts.join(' ➤ ');
}


const eventSchemaData = {
	'@type': 'SportsEvent',
	'name': eventStore.event.display_name,
	'description': viewData.value.description ? stripHtml(viewData.value.description) : null,
	'url': $web('events.show.index', eventStore.event._key, true),
	'image': $image.format(eventStore.event.avatar_thumbnail),
	'startDate': eventStore.event.start_date,
	'endDate': eventStore.event.end_date,
	'eventStatus': eventStore.event.status.value === EventStatus.Canceled ? 'EventCancelled' : 'EventScheduled',
	'eventAttendanceMode': eventStore.event.attendance_mode === EventAttendanceMode.Attendance ? 'OfflineEventAttendanceMode' : (eventStore.event.attendance_mode === EventAttendanceMode.Virtual ? 'OnlineEventAttendanceMode' : 'MixedEventAttendanceMode'),
	'location': {
		'@type': 'Place',
		'name': eventStore.event.location_name,
		'address': {
			'@type': 'PostalAddress',
			'addressLocality': eventStore.event.city,
			'addressRegion': eventStore.event.state_code?.toUpperCase(),
			'addressCountry': eventStore.event.country_code?.toUpperCase(),
			'postalCode': eventStore.event.postcode,
			'streetAddress': eventStore.event.street
		}
	}
}

if (viewData.value.clubs.length) {
	eventSchemaData['organizer'] = getClubOrganizationSchema(viewData.value.clubs[0])
}

if (eventStore.event.currentMinPrice) {
	eventSchemaData['offers'] = {
		'@type': 'Offer',
		'price': parseFloat(eventStore.event.currentMinPrice.cents / 100),
		'priceCurrency': 'EUR',
		'validThrough': eventStore.event.currentMinPrice.end_date ?? eventStore.event.start_date,
		'availability': 'https://schema.org/OnlineOnly',
		'url': $web('events.participation_classes.index', eventStore.event._key, true)
	}
}

if (eventStore.event.rating.count) {
	eventSchemaData['aggregateRating'] = {
		'@type': 'AggregateRating',
		'ratingValue': eventStore.event.rating.avg,
		'ratingCount': eventStore.event.rating.count
	}
}

useMeta({
	title: eventStore.buildPageTitle(eventStore.event.display_name),
	description: getSeoDescription(),
});

useSchemaOrg([
	defineEvent(eventSchemaData),
])

</script>

